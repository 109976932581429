import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, Text, TextInput, TouchableOpacity, Picker, Alert, Modal, FlatList, TouchableHighlight, SafeAreaView } from 'react-native';
import { CheckBox } from 'react-native-elements';
import { Table, TableWrapper,Col, Cols, Cell } from 'react-native-table-component';
import Constants from 'expo-constants';
import * as Network from 'expo-network';
import Clipboard from '../components/Clipboard';

const { manifest } = Constants;
const ApiKey = manifest.extra && manifest.extra.amplitudeApiKey;
const DefaultColCount = 3;
const DefaultRowCount = 21;
const CurrentVersion = '0.7.0';
const CurrentUrl = 'https://d.maxvalue.mx/';
const AmplitudeUrl = 'https://api.amplitude.com/2/httpapi';

export const events = {
  USER_OPEN_HOMESCREEN: 'USER_OPEN_HOMESCREEN',
  USER_CHANGE_LOCATION: 'USER_CHANGE_LOCATION',
  USER_CHANGE_CITYTYPE: 'USER_CHANGE_CITYTYPE',
  USER_CHANGE_LIFESTYLE: 'USER_CHANGE_LIFESTYLE',
  USER_UPDATED_SETTINGS: 'USER_UPDATED_SETTINGS',
};

const findById = (data, id) => {
  return data.find(el => el.id === id);
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function setParameter(name, value) {
  var url = window.location;
  url.searchParams.set(name, value);
  return window.location.href;
}

function insertParameter(key, value)
{
    key = encodeURI(key); value = encodeURI(value);
    var kvp = document.location.search.substr(1).split('&');
    var i=kvp.length; var x; while(i--)
    {
        x = kvp[i].split('=');
        if (x[0]==key)
        {
            x[1] = value;
            kvp[i] = x.join('=');
            break;
        }
    }
    if(i<0) {kvp[kvp.length] = [key,value].join('=');}
    var parameter = kvp.join('&');
    return parameter;
}

function copytext(text) {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
}

export default class HomeScreen extends Component {
  currentVersion = CurrentVersion;
  currentUrl = CurrentUrl;
  locationData = require('../data/data_location.json').locations;
  applicationData = require('../data/data_application.json');
  data = require('../data/data_default_state.json');
  uuid = "";
  tableState = [];
  ipAddress = '';

//onPress={() => this.onChangeCheck()}
  constructor(props) {
    super(props);
    this.elementButton = (value, index) => (
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={() => this.onChangeFrequency('-', value, index)}>
          <View style={styles.countBtn}>
            <Text style={styles.btnText}>ー</Text>
          </View>
        </TouchableOpacity>
        <Text style={styles.btnText}>{value}</Text>
        <TouchableOpacity onPress={() => this.onChangeFrequency('+', value, index)}>
          <View style={styles.countBtn}>
            <Text style={styles.btnText}>＋</Text>
          </View>
        </TouchableOpacity>
      </View>
    );

    //<Text style={[styles.textInput,{textAlign:'center'}]}>{value}</Text>
    this.elementCell = (value) => (
      <Text style={{height:60, textAlign:'center', paddingTop:21,
        backgroundColor: `${this.value2Color(value)}`}}>{value}</Text>
    );

    this.elementHeader = (value) => (
      <Text style={[styles.textInput,{textAlign:'center'}]}>{value}</Text>
    );

    this.uuid = getParameterByName('uuid');
    if (this.uuid) {
      this.getStateAsync();
    } else {
      this.getLocationDataAsync();
    }
    this.setStateNoRender();
    this.getApplicationDataAsync();

    this.track(events.USER_OPEN_HOMESCREEN);
  }

  setLocationData(locationId) {
    this.data.location = locationId;
    var location = this.locationData.find(x => x.id === locationId);
    this.data.confirmedCount = location.confirmed;
    this.data.population = location.population;
  }

  onSelectLocation(locationId) {
    this.setLocationData(locationId)
    this.setStateAndRender();
    this.track(events.USER_CHANGE_LOCATION, locationId);
  }

  onSelectCityType(cityTypeId) {
    this.data.cityType = Number(cityTypeId);
    this.setStateAndRender();
    this.track(events.USER_CHANGE_CITYTYPE, cityTypeId);
  }

  onSelectLifeStyle(lifeStyleId) {
    this.data.lifeStyle = lifeStyleId;
    this.data.lifeStyleLabel = findById(this.data.lifeStyleList, lifeStyleId).label;
    this.setStateAndRender();
    this.track(events.USER_CHANGE_LIFESTYLE, lifeStyleId);
  }

  value2Color(value) {
    var dangerColor = '#ff0000';
    var warningColor = '#ffa500';
    var attentionColor = '#ffff00';

    if (typeof value == 'string') {
      value = Number(value.replace('%','').replace('>',''));
    } else if (typeof value == 'number') {
      value = value * 100;
    } else {
      value = 0;
    }

    var color = '';
    if (value) {
      color = 'rgba(255,0,0,' + Math.sqrt(value)/3 + ')';
    } else {
      color = 'rgba(255,255,255,1.0)';
    }

    return color;
  }

  value2Text(value) {
    var text = (value * 100);
    if ( text > 20 ) {
      return '> 20%';
    } else {
      return text.toFixed(3) + '%';
    }
  }

  setMessage() {
    var message = "";
    if (this.data.deathRisk > 0.01) {
      message += "您处于高风险状态下，死亡概率已超过任何其他疾病的年死亡率。";
      message += "请尽量调整避免外出活动，最好控制死亡概率在0.1%以内。";
      message += "建议您选择[足不出户]，并且做好隔离措施。";
    } else if (this.data.deathRisk > 0.001) {
      message += "您处于较高风险状态下，死亡概率接近脑血管疾病的年死亡率。";
      message += "请尽量调整减少外出活动，最好控制死亡概率在0.1%以内。";
      message += "建议您选择[偶尔出门]，出门时务必做好全身防护。";
    } else if (this.data.deathRisk > 0.0001) {
      message += "您处于中等风险状态下，死亡概率已超过一般传染疾病的年死亡率。";
      message += "请减少不必要的与人接触，降低被感染的风险。";
      message += "建议您调整出行及生活方式。";
      if (!this.data.isMasking) {
        message += "如果不能避免出行及与人接触，请经常洗手正确佩戴口罩。";
      }
    } else if (this.data.deathRisk > 0.00001) {
      message += "您处于低风险状态下，不用过度的担心和紧张。";
      message += "只要避免人群聚集的地方即可。";
      message += "建议您选择[正常上班]。";
      if (!this.data.isMasking) {
        message += "如果不能避免出行及与人接触，请经常洗手正确佩戴口罩。";
      }
    } else {
      message += "您的感染风险很低，不用过度的担心和紧张。";
      message += "与其担心病毒，我想您有更重要的事情要做呢。";
      message += "建议您选择[正常上班]，平时注意个人卫生即可。";
    }
    message += "更多降低被感染风险的方法，请参见【风险信息】页面。";
    this.data.message = message;
  }

  setTableResult() {
    // use window in web, and use global in native
    if (!window.data) {
      window.data = this.data;
    }

    // lifeStyle
    var frequencyObject = findById(this.data.tableResult, "frequency");
    frequencyObject.data = findById(this.data.lifeStyleList, this.data.lifeStyle).data;
    //=(I4*M4)*10/I5
    this.data.carrierDensity = this.data.confirmedCount * this.data.carrier2confirmed / this.data.population;
    //=I7*M3/10000
    var riskTouch1 = 0;
    if (this.data.isMasking) {
      riskTouch1 = this.data.carrierDensity * this.data.riskOneTouch * this.data.riskMask / 10000;
    } else {
      riskTouch1 = this.data.carrierDensity * this.data.riskOneTouch / 10000;
    }
    this.data.riskTouch1 = riskTouch1;
    this.data.riskTouch100 = this.data.riskTouch1 * 100;

    // cityType
    var cityTypeRisk = this.data.cityTypeRiskList[this.data.cityType];

    var touchCountData = findById(window.data.tableData, "touchCount").data;
    var moveRiskData = findById(window.data.tableData, "moveRisk").data;
    var stayTimeData = findById(window.data.tableData, "stayTime").data;
    var timeRiskData = findById(window.data.tableData, "timeRisk").data;
    var dailyRiskData = findById(this.data.tableResult, "dailyRisk").data;
    var weeklyRiskData = findById(this.data.tableResult, "weeklyRisk").data;
    var frequencyData = findById(this.data.tableResult, "frequency").data;

    var influentRisk = 0;
    for(var cellNum=0; cellNum<this.data.cellCount; cellNum++) {
      var touchCount = touchCountData[cellNum];
      var moveRisk = moveRiskData[cellNum];
      var stayTime = stayTimeData[cellNum];
      var timeRisk = timeRiskData[cellNum];
      var dailyRisk = dailyRiskData[cellNum];
      var weeklyRisk = weeklyRiskData[cellNum];
      var frequency = frequencyData[cellNum];

      //timeRisk=IF(F12<1,F12,1+(F12-1)*0.1)
      if ( stayTime < 1 ) {
        timeRisk = stayTime;
      } else {
        timeRisk = 1 + (stayTime - 1)*0.1;
      }

      //$I$9*D12*E12*G12
      frequencyData[cellNum] = frequency;
      dailyRisk = riskTouch1 * touchCount * moveRisk * timeRisk * cityTypeRisk;
      dailyRiskData[cellNum] = dailyRisk;
      weeklyRisk = dailyRisk * frequency;
      weeklyRiskData[cellNum] = weeklyRisk;
      influentRisk += weeklyRisk;
    }

    this.data.influentRisk = influentRisk;
    this.data.severeRisk = influentRisk * this.data.severePercent;
    this.data.deathRisk = influentRisk * this.data.deathPercent;
    this.data.recoveryRisk = influentRisk * this.data.recoveryPercent;

    this.setMessage();
    this.changeTitle();
  }

  setTableState() {
    this.setTableResult();
    for(var cellNum=0; cellNum<=this.data.cellCount; cellNum++) {
      this.data.heightArr.push(60);
    }

    var dailyRiskObject = findById(this.data.tableResult, "dailyRisk");
    var weeklyRiskObject = findById(this.data.tableResult, "weeklyRisk");
    var frequencyObject = findById(this.data.tableResult, "frequency");
    var tableState = [];
    var colDailyRisk = [];
    var colWeeklyRisk = [];
    var colFrequency = [];
    for(var cellNum=0; cellNum<=this.data.cellCount; cellNum++) {
      var cellDailyRisk = null;
      var cellWeeklyRisk = null;
      var cellFrequency = null;
      if(cellNum == 0) {
        cellDailyRisk = this.elementHeader(dailyRiskObject.title);
        cellWeeklyRisk = this.elementHeader(weeklyRiskObject.title);
        cellFrequency = this.elementHeader(frequencyObject.title);
      } else {
        var index = cellNum-1;
        cellDailyRisk = this.elementCell(this.value2Text(dailyRiskObject.data[index]));
        cellWeeklyRisk = this.elementCell(this.value2Text(weeklyRiskObject.data[index]));
        cellFrequency = this.elementButton(frequencyObject.data[index], index);
      }
      colDailyRisk.push(cellDailyRisk);
      colWeeklyRisk.push(cellWeeklyRisk);
      colFrequency.push(cellFrequency);
    }
    tableState.push(colDailyRisk);
    tableState.push(colWeeklyRisk);
    tableState.push(colFrequency);
    this.tableState = tableState;
  }

  setStateNoRender() {
    this.setTableState();
    this.state = {
      location: this.data.location,
      confirmedCount: this.data.confirmedCount,
      population: this.data.population,
      cityType: this.data.cityType,
      lifeStyle: this.data.lifeStyle,
      carrierDensity: this.data.carrierDensity,
      riskTouch100: this.data.riskTouch100,
      riskTouch1: this.data.riskTouch1,
      carrier2confirmed: this.data.carrier2confirmed,
      riskOneTouch: this.data.riskOneTouch,
      riskMask: this.data.riskMask,
      isMasking: this.data.isMasking,
      influentRisk: this.data.influentRisk,
      severeRisk: this.data.severeRisk,
      deathRisk: this.data.deathRisk,
      recoveryRisk: this.data.recoveryRisk,
      message: this.data.message,
      heightArr: this.data.heightArr,
      widthArr: this.data.widthArr,
      tableTitle: this.data.tableTitle,
      tableState: this.tableState
    }
  }

  setStateAndRender() {
    this.setTableState();
    //this WORK
    this.setState({
      location: this.data.location,
      confirmedCount: this.data.confirmedCount,
      population: this.data.population,
      cityType: this.data.cityType,
      lifeStyle: this.data.lifeStyle,
      carrierDensity: this.data.carrierDensity,
      riskTouch100: this.data.riskTouch100,
      riskTouch1: this.data.riskTouch1,
      carrier2confirmed: this.data.carrier2confirmed,
      riskOneTouch: this.data.riskOneTouch,
      riskMask: this.data.riskMask,
      isMasking: this.data.isMasking,
      influentRisk: this.data.influentRisk,
      severeRisk: this.data.severeRisk,
      deathRisk: this.data.deathRisk,
      recoveryRisk: this.data.recoveryRisk,
      message: this.data.message,
      heightArr: this.data.heightArr,
      widthArr: this.data.widthArr,
      tableTitle: this.data.tableTitle,
      tableState: this.tableState
    });
  }

  onChangeText(target, text) {
    if (target == 'population') {
      this.data.population = Number(text);
    } else if (target == 'confirmed') {
      this.data.confirmedCount = Number(text);
    }
    this.setStateAndRender();
  }

  onChangeCheck() {
    this.data.isMasking = !this.data.isMasking;
    this.setStateAndRender();
  }

  onChangeFrequency(signal, value, index) {
    if (signal == '+') {
      value = value + 1;
    } else {
      value = value - 1;
    }
    value = Math.max(0,Math.min(100,value));

    var frequencyData = findById(this.data.tableResult, "frequency").data;
    frequencyData[index] = value;
    this.setStateAndRender();
  }

  onShowRiskInfo(value, colNum, cellNum) {
    if (typeof value == 'string') {
      value = Number(value.replace('%','').replace('>',''));
    } else if (typeof value == 'number') {
      value = value * 100;
    } else {
      value = 0;
    }

    if (value > 0) {
      var text = "假设万一被感染给您带来的痛苦相当于100万元损失的话，每增加 ";
      text += value + "% 的感染概率，就相当于您 ";
      text += (value * 10000).toFixed(0);
      text += "元 的经济损失。"
      alert(text);
    }
  }

  onShowInfo() {
    var deathRiskList = [
      { name:"　　　　恶性肿瘤　", risk:0.0015777 },
      { name:"　　　　　心脏病　", risk:0.0013384 },
      { name:"　　　　脑血管病　", risk:0.0012556 },
      { name:"　　呼吸系统疾病　", risk:0.0007661 },
      { name:"　　　损伤和中毒　", risk:0.0003901 },
      { name:"　营养和代谢疾病　", risk:0.0001712 },
      { name:"　　　一般传染病　", risk:0.0000693 }
    ];

    if (this.data.deathRisk && this.data.deathRisk > 0) {
      var currentRisk = {name:"【一周之后的死亡概率】", risk:this.data.deathRisk};
      deathRiskList.push(currentRisk);
      deathRiskList.sort(this.compare);

      var text = "比较其他疾病的[年]人口死亡率：\n\n";
      for (var i=0; i<deathRiskList.length; i++) {
        var risk = deathRiskList[i];
        text += risk.name + ' ' + this.value2Text(risk.risk) + '\n';
      }
      alert(text);
    }
  }

  compare( a, b ){
    var r = 0;
    if( a.risk < b.risk ){ r = 1; }
    else if( a.risk > b.risk ){ r = -1; }
    return r;
  }

  getLocationDataAsync() {
    var url = this.currentUrl + 'get-json-data.php?f=data_location';
    var request = new Request( url, {method: 'GET', mode: 'no-cors'} );
    return fetch(url).then(response => response.json())
      .then(responseJson => {

        var asyncLocationData = responseJson.locations;
        if (asyncLocationData && asyncLocationData.length > 0) {
          this.locationData = asyncLocationData;
        }
        this.setLocationData(this.data.location);
        this.setStateAndRender();

        return responseJson.locations;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getApplicationDataAsync() {
    var notification = this.applicationData.notification;
    if ( notification && notification != '' ) {
      alert(`${notification}`);
    }

    var latestUrl = this.applicationData.latestUrl;
    if ( latestUrl && latestUrl != '' && latestUrl != this.currentUrl ) {
      this.currentUrl = latestUrl;
    }
  }

  getStateAsync() {
    var url = this.currentUrl + 'get-data-state.php?uuid=' + this.uuid;
    return fetch(url).then(response => response.json())
      .then(responseJson => {
        if (responseJson) {
          this.data = responseJson;
          this.setStateAndRender();
        }
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
  }

  postStateAsync() {
    var today = new Date();
    this.data.timestamp = today.toISOString().slice(0,-5)+'+0000';
    this.data.clientId = 'vr-'+this.data.locationId+'-'+this.data.timestamp;
    var json = JSON.stringify(this.data);
    var url = this.currentUrl + 'post-data-state.php';
    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Host': 'maxu.jp'
        },
        body: json,
      }).then(response => response.json())
        .then(responseJson => {
        console.log(JSON.stringify(responseJson));
        //alert(JSON.parse(response.text()));
        var uuid = responseJson._id;
        //alert("response: " + uuid);
        this.uuid = uuid;
        var parameter = insertParameter("uuid", this.uuid);
        var result = this.onSharing(parameter);

        //this will reload the page, it's likely better to store this until finished
        if (result) {
          document.location.search = parameter;
        }

        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
  }

  onSharing(parameter) {
    var url = document.location.href.split('?')[0] + '?' + parameter;
    //alert(Clipboard.isAvailable());
    var success = Clipboard.setString(url);
    //var success = true;
    //copytext(url);
    var message = "您获得了分享链接\n";
    message += url;
    message += "\n请点击微信右上角...";
    message += "\n[发送给朋友]或[分享到朋友圈]";
    var result = window.confirm(message);

    return result;
  }

  changeTitle() {
    var riskLevel = "";
    if (this.data.deathRisk > 0.01) {
      riskLevel = "很高";
    } else if (this.data.deathRisk > 0.001) {
      riskLevel = "较高";
    } else if (this.data.deathRisk > 0.0001) {
      riskLevel = "中等";
    } else if (this.data.deathRisk > 0.00001) {
      riskLevel = "较低";
    } else {
      riskLevel = "很低";
    }
    var cityTypeList = ["大城市", "中小城市", "郊区", "农村"];
    var cityType = cityTypeList[this.data.cityType];
    var locationStr = this.data.location;
    var confirmed = this.data.confirmedCount;
    var lifeStyleStr = this.data.lifeStyleLabel;
    var influentRisk = this.value2Text(this.data.influentRisk);
    var deathRisk = this.value2Text(this.data.deathRisk);
    var severeRisk = this.value2Text(this.data.severeRisk);
    var today = new Date();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var todayStr = month + '月' + day + '日';
    var title = "["+locationStr+"]"+todayStr+"|确诊"+confirmed+"|感染风险"+influentRisk+"!"+riskLevel+"!"+lifeStyleStr+"|"+cityType;
    this.data.title = title;
    document.title = title;
  }

  track(event: string, locationId?: string) {
    if (!this.ipAddress) {
      this.ipAddress = Network.getIpAddressAsync();
    }
    if (!location) {
      locationId = "undefined";
    }
    return fetch(AmplitudeUrl,{
        method: 'post',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          'Host': 'maxu.jp'
        },
        body: JSON.stringify({
          "api_key": ApiKey,
          "events": [{
              "deveice_id": "C8F9E604-F01A-4BD9-95C6-8E5357DF265D",
              "user_id": "someone",
              "event_type": event,
              "user_properties": {"name": "undefined"},
              "country": "China",
              "region": locationId,
              "ip": this.ipAddress}]
        }),
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const state = this.state;
    return (
      <ScrollView style={styles.container}>
        <View style={{padding: 8, alignItems: 'flex-end'}}>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>保存数据并分享 </Text>
            <TouchableHighlight
              onPress={() => {
                this.postStateAsync();
              }}>
              <Text style={styles.selectBtn}>保存分享</Text>
            </TouchableHighlight>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>更新疫情数据及参数 </Text>
            <TouchableHighlight
              onPress={() => {
                this.getLocationDataAsync();
              }}>
              <Text style={styles.selectBtn}>数据更新</Text>
            </TouchableHighlight>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>请选择您所在的省市地区 </Text>
            <Picker style={{height: 40, width: 100}} selectedValue={state.location}
              onValueChange={(itemValue, itemIndex) =>
                this.onSelectLocation(itemValue)
            }>
              <Picker.Item label="" value="" />
              <Picker.Item label="湖北" value="湖北" />
              <Picker.Item label="浙江" value="浙江" />
              <Picker.Item label="广东" value="广东" />
              <Picker.Item label="河南" value="河南" />
              <Picker.Item label="湖南" value="湖南" />
              <Picker.Item label="江西" value="江西" />
              <Picker.Item label="安徽" value="安徽" />
              <Picker.Item label="重庆" value="重庆" />
              <Picker.Item label="山东" value="山东" />
              <Picker.Item label="四川" value="四川" />
              <Picker.Item label="江苏" value="江苏" />
              <Picker.Item label="上海" value="上海" />
              <Picker.Item label="北京" value="北京" />
              <Picker.Item label="福建" value="福建" />
              <Picker.Item label="陕西" value="陕西" />
              <Picker.Item label="广西" value="广西" />
              <Picker.Item label="河北" value="河北" />
              <Picker.Item label="云南" value="云南" />
              <Picker.Item label="黑龙江" value="黑龙江" />
              <Picker.Item label="海南" value="海南" />
              <Picker.Item label="辽宁" value="辽宁" />
              <Picker.Item label="山西" value="山西" />
              <Picker.Item label="天津" value="天津" />
              <Picker.Item label="甘肃" value="甘肃" />
              <Picker.Item label="宁夏" value="宁夏" />
              <Picker.Item label="内蒙古" value="内蒙古" />
              <Picker.Item label="新疆" value="新疆" />
              <Picker.Item label="贵州" value="贵州" />
              <Picker.Item label="吉林" value="吉林" />
              <Picker.Item label="香港" value="香港" />
              <Picker.Item label="台湾" value="台湾" />
              <Picker.Item label="青海" value="青海" />
              <Picker.Item label="澳门" value="澳门" />
              <Picker.Item label="西藏" value="西藏" />
              <Picker.Item label="日本" value="日本" />
              <Picker.Item label="新加坡" value="新加坡" />
              <Picker.Item label="泰国" value="泰国" />
              <Picker.Item label="韩国" value="韩国" />
              <Picker.Item label="马来西亚" value="马来西亚" />
              <Picker.Item label="澳大利亚" value="澳大利亚" />
              <Picker.Item label="德国" value="德国" />
              <Picker.Item label="越南" value="越南" />
              <Picker.Item label="美国" value="美国" />
              <Picker.Item label="法国" value="法国" />
              <Picker.Item label="加拿大" value="加拿大" />
              <Picker.Item label="其他" value="其他" />
            </Picker>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>输入您所在地的人口(万人)</Text>
            <TextInput keyboardType='numeric'
              style={styles.texInput}
              onChangeText={text => this.onChangeText('population',text)}
              value={`${state.population}`}
            />
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>输入您所在地的确诊数(人)</Text>
            <TextInput keyboardType='numeric'
              style={styles.texInput}
              onChangeText={text => this.onChangeText('confirmed', text)}
              value={`${state.confirmedCount}`}
            />
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>请选择您所在地的类别 </Text>
            <Picker style={{height: 40, width: 100}} selectedValue={state.cityType}
              onValueChange={(itemValue, itemIndex) =>
                this.onSelectCityType(itemValue)
            }>
              <Picker.Item label="大城市" value="0" />
              <Picker.Item label="中小城市" value="1" />
              <Picker.Item label="郊区" value="2" />
              <Picker.Item label="农村" value="3" />
            </Picker>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.text}>请选择您的生活状态 </Text>
            <Picker style={{height: 40, width: 100}} selectedValue={state.lifeStyle}
              onValueChange={(itemValue, itemIndex) =>
                this.onSelectLifeStyle(itemValue)
            }>
              <Picker.Item label="正常上班" value="normalWork" />
              <Picker.Item label="偶尔出门" value="rarelyOutside" />
              <Picker.Item label="足不出户" value="notOutside" />
              <Picker.Item label="出门远行" value="onTravel" />
            </Picker>
          </View>
        </View>

        <View style={{padding: 16}}>
          <TouchableOpacity onPress={() => this.onShowInfo()}>
            <Text style={styles.text}>＜风险估测＞(点击查看详情)</Text>
            <Text style={styles.text}>所在地区病毒携带者密度　{`${state.carrierDensity.toFixed(3)}`}人/万人</Text>
            <Text style={styles.text}>每接触100人的被感染概率　{`${this.value2Text(state.riskTouch100)}`}</Text>
            <Text style={[styles.text, {backgroundColor: `${this.value2Color(state.influentRisk)}`}]}>
            一周以内的【被感染概率】　{`${this.value2Text(state.influentRisk)}`}</Text>
            <Text style={[styles.text, {backgroundColor: `${this.value2Color(state.severeRisk)}`}]}>
            一周之后的【重症概率】　{`${this.value2Text(state.severeRisk)}`}</Text>
            <Text style={[styles.text, {backgroundColor: `${this.value2Color(state.deathRisk)}`}]}>
            一周之后的【死亡概率】　{`${this.value2Text(state.deathRisk)}`}</Text>
            <Text style={styles.text}>一周之后的【治愈概率】　{`${this.value2Text(state.recoveryRisk)}`}</Text>
            <Text style={styles.textArea}>{state.message}</Text>
          </TouchableOpacity>
        </View>

        <View style={{alignItems: 'flex-end'}}>
          <CheckBox center iconRight title='每天注意洗手并正确佩戴口罩 ' checked={state.isMasking}
          onPress={() => this.onChangeCheck()}
          />
        </View>

        <Table style={{flexDirection: 'row'}} borderStyle={{borderWidth: 1}}>
          {/* Left Wrapper */}
          <TableWrapper style={{width: 120}}>
            <Cell data="" style={styles.singleHead}/>
            <TableWrapper style={{flexDirection: 'row'}}>
              <Col data={['居家工作', '近程出行', '远程出行', '生活活动']}
              style={styles.head}
              heightArr={[240, 300, 300, 420]}
              textStyle={styles.tableText} />
              <Col data={state.tableTitle}
              style={styles.title}
              heightArr={state.heightArr}
              textStyle={styles.titleText}></Col>
            </TableWrapper>
          </TableWrapper>

          {/* Right Wrapper */}
          <TableWrapper style={{flex:1}}>
            <Cols data={state.tableState}
            heightArr={state.heightArr}
            textStyle={styles.text}/>
          </TableWrapper>
        </Table>

        <Text style={styles.copyrightText}>copyright@2020, MaxValue.mx</Text>
      </ScrollView>
    )
  }
}

HomeScreen.navigationOptions = {
  title: 'MaxValue.mx',
};

const styles = StyleSheet.create({
  texInput: {
    fontSize: 14,
    textAlign: 'center',
    width: 100,
    height:40,
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  item: {
    backgroundColor: '#c8e1ff',
    padding: 8,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 14,
  },
  container: { padding: 16, paddingTop: 30, backgroundColor: '#fff'},
  singleHead: { height: 60, backgroundColor: '#c8e1ff' },
  head: { flex: 1, backgroundColor: '#c8e1ff'},
  title: { flex: 2, backgroundColor: '#f6f8fa' },
  titleText: { fontSize: 14, marginRight: 6, textAlign:'right' },
  text: { fontSize: 14, textAlign: 'left', height: 40, paddingTop: 8 },
  textArea: { fontSize: 14, textAlign: 'left', height: 120, paddingTop: 8 },
  selectBtn: { textAlign: 'center', paddingTop: 8, height: 40, width: 100, backgroundColor: '#c8e1ff', borderRadius: 2 },
  linkText: { fontSize: 14, textAlign: 'left', height: 40, color: 'blue' },
  tableText: { fontSize: 14, textAlign: 'center', height: 60 },
  btn: { width: 60, height: 40, marginLeft: 15, backgroundColor: '#c8e1ff', borderRadius: 2 },
  countBtn: { width: 24, height: 60, marginLeft: 0, backgroundColor: '#c8e1ff', borderRadius: 2 },
  copyrightText: { fontSize: 14, textAlign: 'center', height: 90, paddingTop: 40 },
  btnText: { fontSize: 14, textAlign: 'center', paddingTop: 21 }
});
