import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, Text, TextInput, Alert } from 'react-native';
import { Table, TableWrapper,Col, Cols, Cell } from 'react-native-table-component';

const DefaultColCount = 3;
const DefaultRowCount = 21;

const findById = (data, id) => {
  return data.find(el => el.id === id);
}

export default class SettingsScreen extends Component {

  data = require('../data/data_default_state.json');
  tableState = [];

//onPress={() => this.onChangeCheck()}
  constructor(props) {
    super(props);

    this.elementTextInput = (value, colId, index) => (
      <TextInput keyboardType='numeric' editable={true}
      style={{fontSize: 14, height:60, textAlign:'center', textAlignVertical:'center'}}
      onChangeText={text => this.onChangeText(text, colId, index)} value={value} />
    );

    this.elementCell = (value) => (
      <Text style={[styles.textInput,{textAlign:'center'}]}>{value}</Text>
    );

    // use window in web, and use global in native
    if (!window.data) {
      window.data = this.data;
    }
    this.setStateNoRender();
  }

  setTableState() {
    for(var cellNum=0; cellNum<=window.data.cellCount; cellNum++) {
      this.data.heightArr.push(60);
    }

    var tableState = [];
    for (var colObject of window.data.tableData) {
      var col = [];
      var colId = colObject.id;
      var colHeader = colObject.title;
      if ( colId == 'touchCount' || colId == 'moveRisk' || colId == 'stayTime' ) {
        for(var cellNum=0; cellNum<=window.data.cellCount; cellNum++) {
          var cell = null;
          if (cellNum!=0) {
            var index = cellNum-1;
            cell = this.elementTextInput(colObject.data[index].toFixed(), colId, index);
          } else {
            cell = this.elementCell(colObject.title);
          }
          col.push(cell);
        }
        tableState.push(col);
      }
    }
    this.tableState = tableState;
  }

  setStateNoRender() {
    this.setTableState();
    this.state = {
      location: this.data.location,
      confirmedCount: this.data.confirmedCount,
      population: this.data.population,
      carrierDensity: this.data.carrierDensity,
      riskTouch100: this.data.riskTouch100,
      riskTouch1: this.data.riskTouch1,
      carrier2confirmed: this.data.carrier2confirmed,
      riskOneTouch: this.data.riskOneTouch,
      riskMask: this.data.riskMask,
      isMasking: this.data.isMasking,
      influentRisk: this.data.influentRisk,
      severeRisk: this.data.severeRisk,
      deathRisk: this.data.deathRisk,
      recoveryRisk: this.data.recoveryRisk,
      heightArr: this.data.heightArr,
      widthArr: this.data.widthArr,
      tableTitle: this.data.tableTitle,
      tableState: this.tableState,
      modalVisible: false
    }
  }

  setStateAndRender() {
    this.setTableState();
    //this WORK
    this.setState({
      location: this.data.location,
      confirmedCount: this.data.confirmedCount,
      population: this.data.population,
      carrierDensity: this.data.carrierDensity,
      riskTouch100: this.data.riskTouch100,
      riskTouch1: this.data.riskTouch1,
      carrier2confirmed: this.data.carrier2confirmed,
      riskOneTouch: this.data.riskOneTouch,
      riskMask: this.data.riskMask,
      isMasking: this.data.isMasking,
      influentRisk: this.data.influentRisk,
      severeRisk: this.data.severeRisk,
      deathRisk: this.data.deathRisk,
      recoveryRisk: this.data.recoveryRisk,
      heightArr: this.data.heightArr,
      widthArr: this.data.widthArr,
      tableTitle: this.data.tableTitle,
      tableState: this.tableState,
      modalVisible: false
    });
  }

  onChangeText(text, colId, index) {
    var value = Number(text);
    if ( value >= 0 ) {
      var colObject = findById(window.data.tableData, colId);
      colObject.data[index] = value;
    }

    this.setStateAndRender();
  }

  render() {
    const state = this.state;
    return (
      <ScrollView style={styles.container}>

        <View style={{padding: 16}}>
          <Text style={styles.text}>＜使用说明＞</Text>
          <Text style={{fontSize: 14,height: 120}}>本程序使用以下的简单公式来估算新型冠状病毒的被感染概率。目的是帮助疫情中的人们提高风险意识，选择正确的出行活动方式。只适用于概率小于20%的情况。如有意见，请联系 contact@maxvalue.mx</Text>
          <Text style={styles.text}>＜计算公式＞</Text>
          <Text style={styles.text}>病毒携带者密度</Text>
          <Text style={styles.text}>　＝确诊数＊被感染者倍率/人口</Text>
          <Text style={styles.text}>单次接触感染概率</Text>
          <Text style={styles.text}>　＝病毒携带者密度＊病毒传染强度</Text>
          <Text style={styles.text}>单次(日)感染概率</Text>
          <Text style={{fontSize: 14,height: 48}}>　＝单次接触感染概率＊接触人数＊时间风险＊流动及附加指数</Text>
          <Text style={styles.text}>一周内被感染概率</Text>
          <Text style={styles.text}>　＝单次(日)感染概率＊频率</Text>
          <Text style={styles.text}></Text>
        </View>

        <Text style={styles.text}>默认参数显示如下，可直接修改。</Text>
        <Text style={styles.text}>修改数值后请点击【数据更新】。</Text>

        <Table style={{flexDirection: 'row'}} borderStyle={{borderWidth: 1}}>
          {/* Left Wrapper */}
          <TableWrapper style={{width: 120}}>
            <Cell data="" style={styles.singleHead}/>
            <TableWrapper style={{flexDirection: 'row'}}>
              <Col data={['居家工作', '近程出行', '远程出行', '生活活动']}
              style={styles.head}
              heightArr={[240, 300, 300, 420]}
              textStyle={styles.tableText} />
              <Col data={state.tableTitle}
              style={styles.title}
              heightArr={state.heightArr}
              textStyle={styles.titleText}></Col>
            </TableWrapper>
          </TableWrapper>

          {/* Right Wrapper */}
          <TableWrapper style={{flex:1}}>
            <Cols data={state.tableState}
            heightArr={state.heightArr}
            textStyle={styles.text}/>
          </TableWrapper>
        </Table>

        <Text style={styles.copyrightText}>copyright@2020, MaxValue.mx</Text>

      </ScrollView>
    )
  }
}

SettingsScreen.navigationOptions = {
  title: 'MaxValue.mx',
};

const styles = StyleSheet.create({
  texInput: {
    fontSize: 14,
    width: 100,
    height:60,
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
    textAlign:'center',
    textAlignVertical:'center',
  },
  item: {
    backgroundColor: '#f9c2ff',
    padding: 8,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 14,
  },
  container: { padding: 16, paddingTop: 30, backgroundColor: '#fff' },
  singleHead: { height: 60, backgroundColor: '#c8e1ff' },
  head: { flex: 1, backgroundColor: '#c8e1ff'},
  title: { flex: 2, backgroundColor: '#f6f8fa' },
  titleText: { fontSize: 14, marginRight: 6, textAlign:'right' },
  text: { fontSize: 14, textAlign: 'left', height: 32, textAlignVertical: 'center' },
  selectBtn: { textAlign: 'center', textAlignVertical: 'center', height: 40, width: 100, backgroundColor: '#c8e1ff', borderRadius: 2 },
  linkText: { fontSize: 14, textAlign: 'left', height: 40, color: 'blue' },
  tableText: { fontSize: 14, textAlign: 'center', height: 60 },
  btn: { width: 60, height: 40, marginLeft: 15, backgroundColor: '#c8e1ff', borderRadius: 2 },
  countBtn: { width: 24, height: 60, marginLeft: 15, backgroundColor: '#c8e1ff', borderRadius: 2 },
  copyrightText: { fontSize: 14, textAlign: 'center', height: 90, paddingTop: 40 },
  btnText: { fontSize: 14, textAlign: 'center' }
});
