import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import Touchable from 'react-native-platform-touchable';

export default class LinksScreen extends React.Component {
  render() {
    return (
      <ScrollView style={styles.container}>
        <Text style={styles.optionsTitleText}>＜相关链接＞</Text>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://voice.baidu.com/act/newpneumonia/newpneumonia/'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>百度 疫情实时大数据报告</Text>
            </View>
          </View>
        </Touchable>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://news.163.com/special/epidemic/'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>网易 肺炎疫情实时动态播报</Text>
            </View>
          </View>
        </Touchable>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://ncov.dxy.cn/ncovh5/view/pneumonia'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>丁香园 疫情实时动态</Text>
            </View>
          </View>
        </Touchable>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://ncov.html5.qq.com/community?channelid=1&from=groupmessage&isappinstalled=0'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>人民日报 新冠肺炎小区查询</Text>
            </View>
          </View>
        </Touchable>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://qianxi.baidu.com/?from=mappc'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>百度迁徙大数据</Text>
            </View>
          </View>
        </Touchable>

        <Text style={styles.optionsTitleText}></Text>
        <Text style={styles.optionsTitleText}>＜关于我们＞</Text>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://play.google.com/store/apps/details?id=mx.maxvalue.virusrisk'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>病毒风险Android版(从GooglePlay下载)</Text>
            </View>
          </View>
        </Touchable>

        <Touchable
          style={styles.option}
          background={Touchable.Ripple('#ccc', false)}
          onPress={() => { WebBrowser.openBrowserAsync('https://MaxValue.mx/'); }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.optionTextContainer}>
              <Text style={styles.optionText}>MaxValue.mx网站（日文）</Text>
            </View>
          </View>
        </Touchable>

        <Text style={styles.copyrightText}>相关链接版权归各个网站所有，仅供参考。</Text>
        <Text style={styles.copyrightText}>copyright@2020, MaxValue.mx</Text>

      </ScrollView>
    );
  }
}

LinksScreen.navigationOptions = {
  title: 'MaxValue.mx',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 15,
  },
  optionsTitleText: {
    fontSize: 16,
    marginLeft: 15,
    marginTop: 9,
    marginBottom: 12,
  },
  optionIconContainer: {
    marginRight: 9,
  },
  option: {
    backgroundColor: '#fdfdfd',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#EDEDED',
  },
  optionText: {
    fontSize: 14,
    marginTop: 1,
  },
  copyrightText: { fontSize: 14, textAlign: 'center', height: 90, paddingTop: 40 },
});
